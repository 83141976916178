<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/admin/${resource.route}`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">
                <template #field-word>
                    <sc-form-field field="word" :form="form" name="Bad Word*"
                                   description='Add multiple words at once by comma separation. E.g. duck, goose.'>
                        <input v-model="words"/>
                    </sc-form-field>
                </template>
            </sc-form>
        </div>
    </div>

</template>

<script>
export default {
  name: "new",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      words: '',
      form: null,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        word: {
          description: 'Add multiple words at once by comma separation. E.g. duck, goose.'
        }
      },
      success: () => {
        this.$notify.success(`${this.resource.name}(s) added`)
        this.$router.push(`/app/admin/${this.resource.route}`)
      }
    })
  },

  methods: {
    submit() {
      this.form.fields.word = this.words.split(',')
      this.form.post()
    }
  }
}
</script>

<style scoped lang="scss">

</style>